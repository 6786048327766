var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-card-header',[_c('b',{staticClass:"h1"},[_vm._v("Basic information")])]),_c('b-card-body',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"*Avatar:","label-cols-md":"3"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-center",staticStyle:{"max-width":"200px"}},[_c('b-aspect',{attrs:{"aspect":"1:1"}},[_c('b-img-lazy',{staticStyle:{"position":"absolute","max-width":"200px"},attrs:{"src":_vm.model.avatar,"fluid":"","thumbnail":"","rounded":""}}),_c('div',{staticClass:"fade-browse d-flex align-items-center justify-content-center w-100 h-100 rounded"},[_c('b-form-file',{staticClass:"w-100 h-100 d-flex align-items-center justify-content-center img-box",attrs:{"accept":".jpg, .png, .gif"},on:{"change":_vm.previewFiles}}),_c('b-button',{staticClass:"btn-icon rounded-circle btn-add",attrs:{"variant":"outline-primary"}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)],1)],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Full name:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Full name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Full name"},model:{value:(_vm.model.fullname),callback:function ($$v) {_vm.$set(_vm.model, "fullname", $$v)},expression:"model.fullname"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Email:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"type":"email","placeholder":"Email"},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Birthday:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Birthday","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Birthday","date-format-options":{
                        day: 'numeric',
                        month: 'numeric',
                        year: 'numeric',
                      },"locale":"vi"},model:{value:(_vm.model.birthday),callback:function ($$v) {_vm.$set(_vm.model, "birthday", $$v)},expression:"model.birthday"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Gender:","label-cols-md":"3"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('b-form-radio',{attrs:{"value":0},model:{value:(_vm.model.gender),callback:function ($$v) {_vm.$set(_vm.model, "gender", $$v)},expression:"model.gender"}},[_vm._v(" Male ")]),_c('b-form-radio',{staticClass:"ml-3",attrs:{"value":1},model:{value:(_vm.model.gender),callback:function ($$v) {_vm.$set(_vm.model, "gender", $$v)},expression:"model.gender"}},[_vm._v(" Female ")])],1)])],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"*Is active:","label-cols-md":"3"}},[_c('validation-provider',{attrs:{"name":"Is active","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"text","reduce":function (x) { return x.value; },"options":_vm.activeOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.model.is_active),callback:function ($$v) {_vm.$set(_vm.model, "is_active", $$v)},expression:"model.is_active"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-col',{staticClass:"text-right",attrs:{"md":"12"}},[_c('b-button',{staticClass:"text-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }